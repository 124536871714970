import React, { useEffect } from 'react'
import { CircularProgress, Paper, Theme, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { green } from '@mui/material/colors'
import { StringParam, useQueryParam } from 'use-query-params'
import { Layout } from '../theme/layout'
import { NavigationBar } from '../components/navigation-bar'
import { NavigationBarLink } from '../components/navigation-bar-link'
import { isBrowser } from '../../utils/safe-navigation'
import { isAEK } from '../../utils/web-site'
import { NotificationContextProvider } from '../components/app/context-provider/notification-context-provider'
import {
  ReservationContextProvider,
  useReservationContext,
} from '../components/app/context-provider/reservation-context-provider'
import { createRemoteGetReservation } from '../../../main/factories/usecases/remote-get-reservation-factory'
import { createRemoteUpdateReservation } from '../../../main/factories/usecases/remote-update-reservation-factory'

function formatDate(dateStr: any) {
  if (dateStr) {
    return new Date(dateStr).toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }
  return ''
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: 110,
    textAlign: 'center',
    maxWidth: '1184px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: { padding: theme.spacing(2) },
  paper: { padding: 16 },
  textSpacing: { marginTop: 64 },
  progressBar: { display: 'flex', justifyContent: 'center' },
}))

const ConfirmationSummary = ({ reservation }: any) => {
  const { classes } = useStyles()

  useEffect(() => {
    if (isBrowser() && isAEK() && window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-950149709/uE6PCLXFr9UBEM3EiMUD',
        value: 1.0,
        currency: 'EUR',
        transaction_id: '',
      })
    }
  }, [])

  return (
    <div className={classes.container}>
      <Typography variant="h2" align="center" gutterBottom>
        Merci pour votre réservation!
      </Typography>

      <CheckCircleIcon style={{ color: green[500], width: 70, height: 70 }} />

      <Typography variant="h4" align="center" gutterBottom style={{ marginTop: 16 }}>
        Notre équipe sera ravie de vous accueillir le <b>{formatDate(reservation.deposit)}</b>
      </Typography>

      <Typography variant="h5" align="center" gutterBottom className={classes.textSpacing}>
        Un email de confirmation de réservation vous sera envoyé à <b>{reservation.email}</b>
      </Typography>

      <Typography variant="body1" align="center" gutterBottom className={classes.textSpacing}>
        Vous pourrez consulter à tout moment la statut de votre réservation et télécharger votre facture dans "mes
        reservations" de la rubrique "mon compte" sur notre site.
      </Typography>

      <Typography variant="h3" align="center" gutterBottom className={classes.textSpacing}>
        Merci de votre confiance!
      </Typography>
    </div>
  )
}

function parseRef(value: string | null | undefined): number {
  if (value) {
    const parts = value.split('-')

    return parseInt(parts.length > 1 ? parts[1] : parts[0])
  } else {
    return -1
  }
}

const Content = () => {
  const { isLoading, reservation } = useReservationContext()
  const { classes } = useStyles()

  return (
    <Paper className={classes.paper}>
      {isLoading || reservation == null ? (
        <div className={classes.progressBar}>
          <CircularProgress />
        </div>
      ) : (
        <ConfirmationSummary reservation={reservation} />
      )}
    </Paper>
  )
}

export default function confirmation() {
  const { classes } = useStyles()
  const [ref] = useQueryParam('ref', StringParam)
  const reservationId = parseRef(ref)
  const remoteGetReservation = createRemoteGetReservation()
  const remoteUpdateReservation = createRemoteUpdateReservation()

  return (
    <Layout>
      <NotificationContextProvider>
        <NavigationBar>
          <NavigationBarLink to="/#top">Accueil</NavigationBarLink>
          <NavigationBarLink to="/app">Nouvelle réservation</NavigationBarLink>
          <NavigationBarLink to="/app">Espace client</NavigationBarLink>
        </NavigationBar>

        {/* Body */}
        <div className={classes.root}>
          <ReservationContextProvider
            getReservation={remoteGetReservation}
            updateReservation={remoteUpdateReservation}
            reservationId={reservationId}
          >
            <Content />
          </ReservationContextProvider>
        </div>
      </NotificationContextProvider>
    </Layout>
  )
}
